/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-16 10:10:26
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-16 15:00:40
 */
import api from "@/base/utils/request";
import download from "@/base/utils/download";
// 获取所属架构/职务列表
export const positionLevel = (data) => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
  });
};
// 获取捐款统计
export const donationStats = (data) => {
  return api({
    url: "/admin/donation/order/donationStats",
    method: "post",
    data,
  });
};
// 所有班级
export const AllClass = (data) => {
  return api({
      url: '/admin/school/classes/all',
      method: 'post',
      data,
  })
}
