<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="tenantVersion !== 'school' ? commonThead : schoolThead"
      :tab-arr="tenantVersion !== 'school' ? tabCommonArr : tabSchoolArr"
      :on-fetch="getDonationList"
      @stats="getListdone"
    >
      <template v-slot:reminder>
        <Reminder
          v-if="showReminder"
          v-loading="loading"
          :textArr="
            tenantVersion !== 'school' ? ReminderCommonText : ReminderSchoolText
          "
          title="数据公告"
        />
      </template>
      <!-- <template
        v-if="filterForm.tab !== 'checking'"
        #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template> -->
      <template v-slot:filter>
        <list-filter :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template slot="item_info_avatar" slot-scope="{ row }">
        <list-image
          fit="cover"
          :width="40"
          border-radius="50%"
          :src="row.info_avatar"
        />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import listFilter from "../../components/project/DonationFilter";

import Reminder from "@/modules/common/components/Reminder.vue";
import ListImage from "@/base/components/List/ListImage";

import { donationList, softDelete, Pdelete, putBacks } from "../../api/project";
import { donationStats } from "../../api/donation";
export default {
  components: {
    listFilter,
    ListLayout,
    ListStatusText,
    Reminder,
    ListImage,
  },
  data() {
    return {
      tabCommonArr: [
        { label: "个人排行", name: "personal" },
        { label: "企业排行", name: "company" },
      ],
      tabSchoolArr: [
        { label: "班级排行", name: "class" },
        { label: "届别排行", name: "term" },
        // { label: "学院排行", name: "deleted" },
      ],
      // 公共版数据表格
      commonThead: [
        { type: "selection" },
        { label: "排名", prop: "rank", width: 50 },
        { label: "头像", prop: "info_avatar", width: 50, type: "slot" },
        { label: "用户名称", prop: "info_name" },
        {
          label: "会内职务",
          prop: "title_name",
        },
        {
          label: "捐款金额",
          prop: "order_price",
          sortable: true,
        },
        {
          label: "捐款时间",
          prop: "create_time",
          sortable: true,
        },
      ],
      // 校友版数据表格
      schoolThead: [
        { type: "selection" },
        { label: "排名", prop: "rank", minWidth: 50 },
        { label: "头像", prop: "info_avatar", minWidth: 50, type: "slot" },
        { label: "用户名称", prop: "info_name" },
        {
          label: "班级信息",
          prop: "student_info",
        },
        {
          label: "捐款金额",
          prop: "order_price",
        },
        {
          label: "捐款时间",
          prop: "create_time",
        },
      ],
      // 筛选对象
      filterForm: {
        donation_id: this.$route.params.id,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: this.tenantVersion !== "school" ? "personal" : "class", //当前筛选tab
        page_size: 15, //每页多少条数据
        // 会内职务id数组
        positionIds: [],
      },
      // 公共版温馨提示
      ReminderCommonText: [
        "已筹金额/目标金额：¥0 / ¥0",
        "个人捐赠总金额：¥ 0；捐赠排行前 3 名：1、用户名称（捐赠金额：¥ 0）、2、用户名称（捐赠金额：¥ 0）、3、用户名称（捐赠金额：¥ 0）",
        "团队捐赠总金额：¥ 0；捐赠排行前 3 名：1、团队名称（捐赠金额：¥ 0）、2、团队名称（捐赠金额：¥ 0）、3、团队名称（捐赠金额：¥ 0）",
        "企业捐赠总金额：¥ 0；捐赠排行前 3 名：1、企业名称（捐赠金额：¥ 0）、2、企业名称（捐赠金额：¥ 0）、3、企业名称（捐赠金额：¥ 0）",
      ],
      // 校园版温馨提示
      ReminderSchoolText: [
        "已筹金额/目标金额：¥0 / ¥0",
        "班级捐赠总金额：¥ 0；捐赠排行前 3 名：1、班级名称（捐赠金额：¥ 0）、2、班级名称（捐赠金额：¥ 0）、3、班级名称（捐赠金额：¥ 0）",
        "届别捐赠总金额：¥ 0；捐赠排行前 3 名：1、届别名称（捐赠金额：¥ 0）、2、届别名称（捐赠金额：¥ 0）、3、届别名称（捐赠金额：¥ 0）",
        "学院捐赠总金额：¥ 0；捐赠排行前 3 名：1、学院名称（捐赠金额：¥ 0）、2、学院名称（捐赠金额：¥ 0）、3、学院名称（捐赠金额：¥ 0）",
      ],
      loading: false,
      // 是否显示提示
      showReminder: false,
    };
  },
  methods: {
    getDonationList(data) {
      this.loading = true;
      return donationStats(data);
    },
    // 获取到的数据
    getListdone(res) {
      let stats = res.stats;
      console.log(stats.donation.raised);
      // 有捐款
      if (stats.donation.raised) {
        this.ReminderCommonText = [
          `已筹金额/目标金额：¥${stats.donation.raised} / ¥${stats.donation.target_price}`,
          `个人捐赠总金额：¥${stats.personals.total}；捐赠排行前 3 名：`,
          `企业捐赠总金额：¥${stats.companies.total}；捐赠排行前 3 名：`,
        ];
        // 个人捐款
        stats.personals.rank.forEach((item, i) => {
          // 排名字符串
          let str = `${i + 1}、${item.name}（捐赠金额：¥${item.order_price}）`;
          if (i !== 0) {
            // 不是第一个，前面加顿号
            str = `、` + str;
          }
          this.ReminderCommonText[1] += str;
        });
        // 企业捐款
        stats.companies.rank.forEach((item, i) => {
          let str = `${i + 1}、${item.name}（捐赠金额：¥${item.order_price}）`;
          if (i !== 0) {
            // 不是第一个，前面加顿号
            str = `、` + str;
          }
          this.ReminderCommonText[2] += str;
        });

        this.ReminderSchoolText = [
          `已筹金额/目标金额：¥${stats.donation.raised} / ¥${stats.donation.target_price}`,
          `班级捐赠总金额：¥${stats.classes.total}；捐赠排行前 3 名：`,
          `届别捐赠总金额：¥${stats.terms.total}；捐赠排行前 3 名：`,
        ];

        // 班级捐款
        stats.classes.rank.forEach((item, i) => {
          let str = `${i + 1}、${item.name}（捐赠金额：¥${item.order_price}）`;
          if (i !== 0) {
            // 不是第一个，前面加顿号
            str = `、` + str;
          }
          this.ReminderSchoolText[1] += str;
        });
        // 界别捐款
        stats.terms.rank.forEach((item, i) => {
          let str = `${i + 1}、${item.name}（捐赠金额：¥${item.order_price}）`;
          if (i !== 0) {
            // 不是第一个，前面加顿号
            str = `、` + str;
          }
          this.ReminderSchoolText[2] += str;
        });

        this.showReminder = true;
      } else {
        // 没有人捐款
      }
      this.loading = false;
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  created() {
    console.log(this.tenantVersion);
  },
};
</script>

<style lang="scss" scoped></style>
