<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :onExport="onExport"
    :toggleCollapseEnable="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item
          v-if="tenantVersion !== 'school'"
          label="会内职务："
          prop="positionIds"
        >
          <el-cascader
            v-model="positionIds"
            :options="parentPositionList"
            :props="props"
            placeholder="请选择"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item v-else label="搜索班级：" prop="class_id">
          <el-select v-model="form.class_id"  placeholder="请选择">
              <el-option v-for="item in ClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="捐款时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { positionLevel, donationStats,AllClass } from "../../api/donation";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      TypeArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "未开始" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" },
      ],
      ClassList: [], // 全部班级

      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      // 会员职务
      parentPositionList: [],
    };
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
          console.log(this.parentPositionList)
        })
        .catch(() => {});
    },
    // 获取班级
    getAllClass() {
      AllClass().then(res => {
        this.ClassList = res.data
        console.log(this.ClassList)
      }).catch(err => console.log(err))
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      donationExport(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    // 获取会内职务
    this.getPositionLevel();
    // 获取班级
    this.getAllClass()
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },

    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    positionIds: {
      get() {
        return this.form.positionIds;
      },
      set(val) {
        this.form.positionIds = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
